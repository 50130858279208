import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  width: 90%;
  margin: auto;
`
export const Header = styled.h1`
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${(props) => (props.center ? props.theme.blueDark : props.theme.grayDark)};
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    text-align: ${(props) => (props.center ? 'left' : 'center')};
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 36px;
    line-height: 40px;
    text-align: left;
  }
`
export const Subheader = styled.p`
  color: ${(props) => props.theme.darkGray};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 40px auto;
  @media (max-width: ${breakpoints.l}px) {
    gap: 20px;
    grid-template-columns: 1fr;
    div:nth-child(1) {
      grid-row: ${(props) => (props.reverse ? 2 : 1)};
    }
    div:nth-child(2) {
      grid-row: ${(props) => (props.reverse ? 1 : 2)};
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 20px 0px;
  }
`
export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: left;
  }
`
