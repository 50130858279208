export const CAL_PLUS_FEATURES = [
  {
    name: 'Magic Import',
    checked: false,
  },
  {
    name: 'Photo and video screensaver',
    checked: false,
  },
  {
    name: 'Meal Planning',
    checked: false,
  },
  {
    name: 'Dinner Plans',
    checked: true,
    noPlus: true,
  },
  {
    name: 'Sync online calendars',
    checked: true,
  },
  {
    name: 'Chore chart',
    checked: true,
  },
]
