import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Analytics from 'src/utils/Analytics'
import StoreContext from '../../../context/StoreContext'
import { Container, Header, Subheader, GridContainer, Column } from './styles'

import Matrix from '../../Matrix'
import { CalPlusAddToCartButton } from '../CalPlusAddToCartButton'

const CalPlusHero = ({ product }) => {
  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)

  const handleAddToCart = async (variantId, quantity) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Calendar Plus to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        navigate('/cart/')
      }
    }
  }
  return (
    <Container>
      <GridContainer>
        <Column>
          <div>
            <Header>Calendar is better with Plus</Header>
            <Subheader>
              Turn your Calendar into a photo frame, seamlessly pull in events from emails or PDFs
              with Magic Import, and plan the week’s meals in just 5 minutes with Meal Planning.
            </Subheader>

            <CalPlusAddToCartButton
              product={product}
              adding={submitting}
              onClick={() => handleAddToCart(product.variants[0].shopifyId, 1)}
            />
          </div>
        </Column>
        <Column>
          <Matrix />
        </Column>
      </GridContainer>
    </Container>
  )
}

CalPlusHero.propTypes = {
  product: PropTypes.object.isRequired,
}

export default CalPlusHero
