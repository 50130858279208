import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { CAL_PLUS_FEATURES } from './constants'
import {
  MatrixContainer,
  Table,
  Row,
  HeaderCell,
  Cell,
  CheckCell,
  CheckMark,
  Title,
  FeatureName,
} from './styles'

const Matrix = () => {
  const { grayCheck, goldCheck } = useStaticQuery(graphql`
    query MatrixQuery {
      grayCheck: file(relativePath: { eq: "check-gray.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      goldCheck: file(relativePath: { eq: "check-gold.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <MatrixContainer>
      <Table>
        <thead>
          <Row last>
            <HeaderCell>
              <Title>Basic</Title>
            </HeaderCell>
            <HeaderCell>
              <Title>Plus</Title>
            </HeaderCell>
            <HeaderCell />
          </Row>
        </thead>
        <tbody>
          {CAL_PLUS_FEATURES.map((row, index) => (
            <Row key={index} last={index === CAL_PLUS_FEATURES.length - 1}>
              <CheckCell>
                {row.checked && (
                  <CheckMark image={grayCheck.childImageSharp.gatsbyImageData} alt="check mark" />
                )}
              </CheckCell>
              <CheckCell>
                <CheckMark
                  hide={row.noPlus}
                  image={goldCheck.childImageSharp.gatsbyImageData}
                  alt="check mark"
                />
              </CheckCell>
              <Cell>
                <FeatureName>{row.name}</FeatureName>
              </Cell>
            </Row>
          ))}
        </tbody>
      </Table>
    </MatrixContainer>
  )
}

export default Matrix
