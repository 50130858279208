import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const CalPlusImage = styled.div`
  width: 100%;
  img {
    border-radius: 25px;
  }
`
export const SecondaryHeader = styled.h3`
  font-family: 'P22MackinacProBook';
  font-size: 32px;
  line-height: 40px;
  margin: 0px;
  color: ${(props) => props.theme.blueDark};
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
  }
`
