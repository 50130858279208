import { useMemo } from 'react'
import { FormattedCurrency } from '../../utils/FormattedCurrency'

export function useSkylightPlusVariantFromHandle(handle) {
  return useMemo(() => {
    const [hyphenatedPrice] = handle.match(/\d{1,2}?-?\d{2}-(mo|yr)/) ?? []

    if (!hyphenatedPrice) return {}

    const [dollars, cents, abbreviatedCadence] = hyphenatedPrice.split('-')
    const { [abbreviatedCadence]: cadence } = {
      mo: 'month',
      yr: 'year',
    }

    const formattedPrice = [FormattedCurrency(`${dollars}.${cents}`), cadence].join('/')

    const disclaimer = `${formattedPrice} after 2-week free trial`

    return {
      price: formattedPrice,
      label: 'Try Plus for $0.00',
      disclaimer,
    }
  }, [handle])
}
