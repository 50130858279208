import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import { AddToCart } from './styles'

const AddToCartButton = ({
  adding,
  handleClick,
  label,
  addingLabel,
  widerButton,
  oos,
  textcolor,
  backgroundColor,
}) => {
  let labelText
  if (adding) {
    labelText = addingLabel || 'Adding to Cart...'
  } else {
    labelText = label || 'Add to Cart'
  }
  return (
    <AddToCart
      widerButton={widerButton}
      type="button"
      disabled={adding || oos}
      onClick={handleClick}
      oos={oos}
      textcolor={textcolor}
      backgroundColor={backgroundColor}
    >
      <Trans>{labelText}</Trans>
    </AddToCart>
  )
}

AddToCartButton.propTypes = {
  adding: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  addingLabel: PropTypes.string,
  widerButton: PropTypes.bool,
  oos: PropTypes.bool,
  textcolor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default AddToCartButton
