import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoints } from 'src/utils/styles/global-styles'

export const MatrixContainer = styled.div`
  display: inline-block;
  padding: 24px;
  background-color: #faf9eb;
  border-radius: 24px;
  width: 100%;
  @media (max-width: ${breakpoints.l}px) {
    width: 60%;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 16px;
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const Row = styled.tr`
  border-bottom: ${(props) => (props.last ? 'none' : '2px solid #f1f0e0')};
`

export const Cell = styled.td`
  padding: 16px 0px;
`
export const CheckCell = styled.td`
  padding: 16px 0px;
  padding-right: 90px;
  @media (max-width: 1600px) {
    padding-right: 60px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    padding-right: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-right: 20px;
  }
`

export const HeaderCell = styled.th`
  font-size: 38px;
  padding: 0px 50px 10px 0px;
  text-align: left;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 0px 30px 10px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px 20px 0px 0px;
  }
`

export const CheckMark = styled(GatsbyImage)`
  visibility: ${(props) => (props.hide ? 'hidden' : 'visable')};
  width: 22px;
  margin: auto;
`
export const Title = styled.h2`
  font-size: 32px;
  line-height: 40px;
  margin: 0px;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 22px;
  }
`
export const FeatureName = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin: 0px;
  text-align: left;
  @media (max-width: 1350px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 400px) {
    font-size: 15px;
  }
`
